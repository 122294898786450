import React from "react";
import { motion } from "framer-motion";

const Status = ({ status, isTesting }) => (
  <motion.div
    className={`status ${isTesting ? "animate" : ""}`}
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {status}
  </motion.div>
);

export default Status;