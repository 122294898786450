import React from "react";
import { GiSpeedometer } from "react-icons/gi";
import "./Header.css";

const Header = () => (
  <header className="header">
    <h1 className="header-title">
      <GiSpeedometer className="header-icon" /> ANJA-LAZANJA
    </h1>
  </header>
);

export default Header;