const BACKEND_URL = "/api"; // Koristi Nginx proxy umjesto direktnog IP-a
const IPINFO_API_KEY = "c93b5102e817b3"; // Tvoj IPInfo API ključ

/**
 * Fetches the available test servers from the backend.
 * @returns {Promise<Object>} List of test servers.
 */
export const fetchTestServers = async () => {
  try {
    const response = await fetch(`${BACKEND_URL}/test-servers`, { cache: 'no-store' });
    if (!response.ok) {
      throw new Error("Greška pri dobavljanju servera.");
    }
    return response.json();
  } catch (error) {
    console.error("Greška u fetchTestServers:", error);
    throw error;
  }
};

/**
 * Measures the download speed for a given URL.
 * @param {string} url - The URL to download the test file from.
 * @param {function} onProgress - Callback function to handle progress updates. Receives (progress, speed).
 * @returns {Promise<Object>} Speed and duration of the download.
 */
export const measureDownloadSpeed = async (url, onProgress) => {
  try {
    // Dodavanje cache-buster parametra
    const cacheBuster = `cb=${performance.now()}`;
    const urlWithCacheBuster = url.includes('?') ? `${url}&${cacheBuster}` : `${url}?${cacheBuster}`;

    const startTime = performance.now();
    const response = await fetch(urlWithCacheBuster, { cache: 'no-store' });

    if (!response.ok) {
      throw new Error(`Greška pri preuzimanju s URL-a: ${url}`);
    }

    const reader = response.body.getReader();
    const contentLengthHeader = response.headers.get("Content-Length");
    const contentLength = contentLengthHeader ? parseInt(contentLengthHeader, 10) : 50 * 1024 * 1024;

    let downloadedBytes = 0;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      downloadedBytes += value.length;
      const progress = (downloadedBytes / contentLength) * 100;
      const currentTime = performance.now();
      const duration = (currentTime - startTime) / 1000;
      const speedMbps = ((downloadedBytes * 8) / (duration * 1_000_000)).toFixed(2);

      if (onProgress) {
        onProgress(progress.toFixed(2), speedMbps);
      }
    }

    const endTime = performance.now();
    const totalDuration = (endTime - startTime) / 1000;
    const totalSpeedMbps = ((downloadedBytes * 8) / (totalDuration * 1_000_000)).toFixed(2);

    return { speed: totalSpeedMbps, duration: totalDuration.toFixed(2) };
  } catch (error) {
    console.error("Greška u measureDownloadSpeed:", error);
    throw error;
  }
};

/**
 * Fetches the client's IP address and ISP information using IPInfo API.
 * @returns {Promise<Object>} Client's public IP address and ISP details.
 */
export const fetchClientIPWithISP = async () => {
  try {
    const ipResponse = await fetch("https://api64.ipify.org?format=json", { cache: 'no-store' });
    if (!ipResponse.ok) {
      throw new Error("Greška pri dobavljanju IP adrese.");
    }
    const ipData = await ipResponse.json();

    const ispResponse = await fetch(`https://ipinfo.io/${ipData.ip}/json?token=${IPINFO_API_KEY}`, { cache: 'no-store' });
    if (!ispResponse.ok) {
      throw new Error("Greška pri dobavljanju ISP informacija.");
    }
    const ispData = await ispResponse.json();

    return {
      ip: ipData.ip,
      isp: ispData.org || "Nepoznati ISP",
      city: ispData.city || "Nepoznati grad",
      region: ispData.region || "Nepoznati region",
      country: ispData.country || "Nepoznata država",
    };
  } catch (error) {
    console.error("Greška u fetchClientIPWithISP:", error);
    throw error;
  }
};