import React, { useState } from "react";
import Header from "./components/Header";
import Status from "./components/Status";
import ProgressBar from "./components/ProgressBar";
import Results from "./components/Results";
import { fetchTestServers, measureDownloadSpeed, fetchClientIPWithISP } from "./api";
import "./App.css";

const App = () => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("Čekanje...");
  const [results, setResults] = useState([]);
  const [isTesting, setIsTesting] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [currentSpeed, setCurrentSpeed] = useState(null); // Dodano za prikaz trenutne brzine

  const startTest = async () => {
    setIsTesting(true);
    setProgress(0);
    setStatus("Dobavljanje IP adrese i ISP informacija...");
    setResults([]);
    setCurrentSpeed(null);

    try {
      const info = await fetchClientIPWithISP();
      setClientInfo(info);

      setStatus("Dobavljanje servera...");
      const servers = await fetchTestServers();

      setStatus("Pokretanje testa...");
      for (const [serverName, serverUrl] of Object.entries(servers)) {
        setStatus(`Testiranje: ${serverName}`);
        const result = await measureDownloadSpeed(serverUrl, (progress, speed) => {
          setProgress(progress);
          setCurrentSpeed(speed); // Ažurira trenutnu brzinu
        });

        setResults((prev) => [
          ...prev,
          { serverName, ...result },
        ]);
      }

      setStatus("Test završen.");
    } catch (error) {
      setStatus(`Greška: ${error.message}`);
    } finally {
      setIsTesting(false);
      setProgress(100);
      setCurrentSpeed(null);
    }
  };

  return (
    <div className="app-container">
      <Header />
      <main>
        {clientInfo.ip && (
          <p>
            <strong>IP Klijenta:</strong> {clientInfo.ip} <br />
            <strong>ISP:</strong> {clientInfo.isp} <br />
            <strong>Lokacija:</strong> {clientInfo.city}, {clientInfo.region}, {clientInfo.country}
          </p>
        )}
        <button
          className="btn-primary"
          onClick={startTest}
          disabled={isTesting}
        >
          {isTesting ? "Testiranje u toku..." : "Pokreni test"}
        </button>
        <Status status={status} />
        <ProgressBar progress={progress} />
        <Results results={results} />

        {/* Prikaz speedometera samo dok traje test i imamo trenutnu brzinu */}
        {isTesting && currentSpeed !== null && (
          <div className="speedometer-overlay">
            <div className="speedometer-circle">
              <div className="speedometer-speed">
                {currentSpeed} Mbps
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default App;